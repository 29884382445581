import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
    constructor(private sanitized: DomSanitizer){

    }

    /**
     * Constructor
     *
     * @param string value (string with html format to convert)
     */
    transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}