import { Model } from 'common/helpers/models/model.abstract';
import { Local} from 'common/models/local.model';

export class User extends Model {
    local: Local = new Local();

    protected static includes: any = {
        'local': Local
    }

    public constructor(user?: any){
        super();
        user = user || {};
        this.local = user.local || null;
    }

    public static getIncludes(...args: any): string {
        return User.transformIncludes(User.includes, ...args);
    }

    public static classIncludes(): any {
        return User.includes;
    }
}
