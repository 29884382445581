import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GroupByPipe } from 'common/pipes/group-by.pipe';
import { ReversePipe } from 'common/pipes/reverse.pipe';
import { SafeHtmlPipe } from 'common/pipes/safe-html.pipe';
import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        GroupByPipe,
        ReversePipe,
        SafeHtmlPipe,
        FilterPipe, 
        SearchPipe, 
        ShortNamePipe
    ],
    exports: [
        GroupByPipe,
        ReversePipe,
        SafeHtmlPipe,
        FilterPipe, 
        SearchPipe, 
        ShortNamePipe
    ],
    entryComponents: [
    ],
})
export class PipesModule { }