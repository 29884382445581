import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';
import { convertToFormData } from 'common/helpers/resources/convertToFormData';

@Injectable({ providedIn: 'root' })
export class LevelResource extends Resource {
    protected availableParams: Array<string> = [
        'include',
        'label',
        'search',
        '_method'
    ];
    protected availableUrlParams: Array<string> = [
        'id'
    ];

    /**
     * Constructor
     *
     * @param {HttpClient} http
     */
    public constructor(protected http: HttpClient) {
        super("/levels");
    }

    /**
     * Get levels
     *
     * @return any
     */
    public GetLevel(id: any, _success?: any, _error?: any, include?: any): any {
        return this.Get(this.url('/:id'), {id: id, include: include}, _success, _error);
    }

    /**
     * Get level
     *
     * @return any
     */
    public GetLevels(_success?: any, _error?: any, include?: any): any {
        return this.Get(this.url(), {include: include}, _success, _error);
    }

     /**
     *  create
     *
     * @returns
     */
    public Create(data: any, success?: any, error?: any){
        return this.Post(this.url(), data, null, success, error);
    }

    /**
     *  edit
     *
     * @returns
     */
    public Edit(data: any, success?: any, error?: any){
        return this.Post(this.url("/:id"), data, {id: data.id, _method: 'PATCH'}, success, error);
    }

    /**
     *  delete
     *
     * @returns
    */
    public Delete(id: any, success?: any, error?: any){
        return super.Delete(this.url("/:id"), {id: id}, success, error);
    }
}
