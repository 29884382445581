import { Model } from 'common/helpers/models/model.abstract';
import { Category} from 'common/models/category.model';

export class Order extends Model {

    id: number;
    local_id: number;
    user_id: number;
    status_id:number;
    total_without_discount: number;
    wholesales_cart_discount:number;
    total_local_discount:number;
    local_discount:number;
    total:number;
    products: any;
    created_at: any;
    status: any;
    address: any;
    shipping:number;
    is_order: boolean = true;
    payment_extra: number;
    total_payment: number;
    payment_method: string;
    weight: number;
    conekta_order:any;

    protected static includes: any = {
        'category': Category
    }

       /**
     * Constructor
     *
     * @param publication
     */
    public constructor(order?: any) {
        super();
        order = order || {};
        this.id = order.id || null;
        this.user_id = order.user_id || null;
        this.local_id = order.local_id || null;
        this.status_id = order.status_id || null;
        this.total_without_discount = order.total_without_discount || 0;
        this.total_local_discount = order.total_local_discount || 0;
        this.wholesales_cart_discount = order.wholesales_cart_discount || 0;
        this.local_discount = order.local_discount || null;
        this.total = order.total || null;
        this.payment_extra = order.payment_extra || null;
        this.total_payment = order.total_payment || null;
        this.created_at = order.created_at || null;
        this.status = order.status || null;
        this.shipping = order.shipping || 0;
        this.is_order = true;
        this.weight = order.weight || null;
        this.payment_method = order.payment_method || null;
        this.address = order.address || null;
        this.conekta_order = order.conekta_order || null;
        this.products = order.products || [];
    }


    public static getIncludes(...args: any): string {
        return Order.transformIncludes(Order.includes, ...args);
    }

    public static classIncludes(): any {
        return Order.includes;
    }
}
