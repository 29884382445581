import { Model } from 'common/helpers/models/model.abstract';
import { Photo } from 'common/models/photo.model';

export class Category extends Model {
    id: number;
    name: string;
    photo:string;
    // description: string;
    // photo_id: number;
    // parent_id: any;
    // special_photo_id: number;
    // background_photo_id: number;
    // photo: Photo;
    // specialPhoto: Photo;
    // backgroundPhoto: Photo;
    // label: string;
    // subcategories: Category[];
    // parentCategory: Category;

    disabled: boolean;

    protected static includes: any = {
        'photo': Photo,
        // 'subcategories.photo': Category,
    };

    /**
     * Constructor
     *
     * @param category
     */
    public constructor(category?: any) {
        super();
        category = category || {};
        console.log("constructor category", category);
        this.id = category.id || null;
        // this.parent_id = category.parent_id || null;
        this.name = category.name || '';
        // this.description = category.description || '';
        // this.label = category.label || null;
        this.photo = category.photo || null;
        // this.specialPhoto = category.specialPhoto || null;
        // this.backgroundPhoto = category.backgroundPhoto || null;
        // this.subcategories = category.subcategories || [];
        // this.parentCategory = category.parentCategory || null;
    }

    public static getIncludes(...args: any): string {
        return Category.transformIncludes(Category.includes, ...args);
    }

    public static classIncludes(): any {
        return Category.includes;
    }
}
