import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { appConfig } from 'common/app-config';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LevelResource } from 'common/resources';

@Component({
    selector: 'modal-editLevel',
    templateUrl: 'edit-level.component.html',
    styleUrls: ['edit-level.component.scss'],
})

export class ModalEditLevel {
    levelForm: FormGroup;
    appConfig: any;
    levelFormSubmitted: boolean = false;
    loading: boolean;
    server_error: string = '';
    public constructor(
        private _formBuilder: FormBuilder,
        public activeModal: NgbActiveModal,
        private spinner: NgxSpinnerService,
        public toastr: ToastrService,
        private levelResource: LevelResource,
    ) {
        this.appConfig = appConfig;
    }

    @Input() level;

    /**
 * On init
 */
    ngOnInit(): void {
        this.levelForm = this.createLevelForm();
    }

    get lf() {
        return this.levelForm.controls;
     }

    /**
     * Create login form
     *
     * @returns {FormGroup}
    */
    private createLevelForm(): FormGroup {
        return this._formBuilder.group({
            name: [{value: this.level.label, disabled: true}],
            level: [{value: this.level.level, disabled: true}],
            low_points: [{value: this.level.limit_low_points, disabled: true}],
            price: [this.level.subscription_price, [Validators.min(1), Validators.required]],
        });
    }

    public closeModal(data?: any){
        //this._dialogRef.close(data);
    }

    private successEditLevel(response: any): void {
        console.log("success onClickEditLevel", response);
        this.spinner.hide();
        this.toastr.success('Nivel actializado correctamente', '', { "progressBar": true });
        this.activeModal.close('Close click')
    }

    private errorEditLevel(error: any): void {
        console.log("error onClickEditLevel", error);
        this.spinner.hide();
        this.server_error = error;
    }

    public onClickEditLevel() {
        console.log("this.form", this.levelForm);
        var {invalid, value: formData} = this.levelForm;
        this.levelFormSubmitted = true;

        if(invalid){
          return;
        }
        this.showSpinner();

        var dataToSend = {
            id: this.level.id,
            label: this.level.label,
            level: this.level.level,
            limit_low_points: this.level.limit_low_points,
            subscription_price: parseInt(formData.price)
        }
        console.log("dataToSend", dataToSend);


        this.levelResource.Edit(dataToSend, (response)=>{
            this.successEditLevel(response);
        }, (error) => {
            this.errorEditLevel(error);
            
        });
    }

    showSpinner(type?: string, bgColor?: string, color?: string){
      this.spinner.show(undefined, {
        type: type || 'ball-triangle-path',
        size: 'medium',
        bdColor: bgColor || 'rgba(0, 0, 0, 0.8)',
        color: color || '#fff',
        fullScreen: true
     });
    }
}
