import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '/pages/home', title: 'Inicio', icon: 'ft-bar-chart-line', class: '', isExternalLink: false, submenu: []
  },
  {
    path: '/pages/users', title: 'Clientes', icon: 'ft-user', class: '', isExternalLink: false, submenu: []
  },
  {
    path: '/pages/categories', title: 'Categorias', icon: 'ft-folder', class: '', isExternalLink: false, submenu: []
  },
  {
    path: '/pages/products', title: 'Productos', icon: 'ft-shopping-bag', class: 'has-sub', isExternalLink: false, 
    submenu: [
      { path: '/pages/products', title: 'Lista de productos', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/product', title: 'Agregar producto', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/create-multiple-product', title: 'Agregar Multiples productos', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/pages/products-deleted', title: 'Productos borrados', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/pages/orders', title: 'Pedidos', icon: 'ft-shopping-cart', class: '', isExternalLink: false, submenu: []
  },
  {
    path: '/pages/discount', title: 'Descuento del local', icon: 'ft-dollar-sign', class: '', isExternalLink: false, submenu: []
  },
  // {
  //   path: '/pages/price-delivery', title: 'Precios de entrega', icon: 'ft-send', class: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/pages/informations', title: 'Informacion del local', icon: 'ft-home', class: '', isExternalLink: false, submenu: []
  }
];
