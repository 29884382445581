// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  	production: false,


    // apiUrl: 'http://api.orienteimportcenter.com/v1',
    // fileUrl: 'http://api.orienteimportcenter.com/uploads/'

    // apiUrl: 'http://api.oic.com/v1',
    // fileUrl:'http://api.oic.com/v1'

    apiUrl: 'https://apiv2.orienteimportcenter.com/v1',
    fileUrl: 'https://apiv2.orienteimportcenter.com/uploads/'
};
