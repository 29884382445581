import { Injectable } from '@angular/core';
import { AuthenticationService } from 'common/services/authentication.service';

@Injectable({providedIn: 'root'})
export class AppService {

	public constructor(
        private authService: AuthenticationService,
	) {}

	init() {
		return new Promise((resolve, reject) => {
            Promise.all([
                this.getUser()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
	}

    /**
     * Get user
     *
     * @returns {Promise<any>}
     */
    getUser(): Promise<any>{
        return new Promise((resolve, reject) => {
            console.log("antes del authCheck");
            this.authService.authCheck((response: any) => {
                console.log("response auth check", response);
                // this.cartService.initCart();
				resolve(response);
			}, (error: any) => {
                console.log("error en el authCheck: ", error);
                // this.cartService.initCart();
				resolve(true);
			});
        });
    }
}
