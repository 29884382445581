<div class="modal-header">
  <h4 class="modal-title">Editar Nivel</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <form class="mb-1" id="levelForm" name="levelForm" [formGroup]="levelForm" novalidate (ngSubmit) = "onClickEditLevel()">
                <div class="col-12 d-inline-block">
                    <div class="form-group">
                      <label for="name">Nombre</label>
                      <input type="text" formControlName="name" class="form-control" id="name" name="name"  />
                    </div>
                </div>

                <div class="col-12 d-inline-block">
                    <div class="form-group">
                      <label for="level">Nivel*</label>
                      <input type="text" formControlName="level" class="form-control" id="level" name="level"/>
                    </div>
                </div>

                <div class="col-12 d-inline-block">
                    <div class="form-group">
                      <label for="low_points">Mínimo de puntos*</label>
                      <input type="text" formControlName="low_points" class="form-control" id="low_points" name="low_points" />
                    </div>
                </div>

                <div class="col-12 d-inline-block">
                    <div class="form-group">
                      <label for="price">Precio*</label>
                      <input #price type="text" formControlName="price" class="form-control" id="price" name="price"
                          [ngClass]="{'is-invalid': levelFormSubmitted && lf.price.invalid, 'is-valid': levelFormSubmitted && !lf.price.invalid}"  />
                      <div *ngIf="levelFormSubmitted && (lf.price.invalid || lf.price.errors?.required)" class="invalid-tooltip" style="position: relative;">
                        El precio es requerido.
                      </div>
                    </div>
                </div>

                <div class="m-b-md message-box error" [innerHTML]="server_error" *ngIf="server_error"></div>
            </form>
        </div><!-- End .row -->
    </div><!-- End .container -->
</div>
<div class="modal-footer">
  <button type="submit" form="levelForm" class="btn btn-outline-info btn-sm">Guardar</button>
  <button type="button" class="btn btn-outline-danger btn-sm" (click)="activeModal.close('Close click')">Cancelar</button>
</div>
<ngx-spinner></ngx-spinner>