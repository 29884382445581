import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Auth, Local } from 'common/models';
import { LocalResource } from 'common/resources';


@Injectable({ providedIn: 'root' })
export class LocalService {
    public currentLocal: BehaviorSubject<any>;

    public constructor(private localResource : LocalResource){
        this.currentLocal = new BehaviorSubject(new Local);
    }

    init(local?: any){
        if(!local){
            local = new Local(JSON.parse(localStorage.getItem('currentLocal')) );
        }

        this.updateLocalValue( (local) ? local : new Local());
    }

    public get currentLocalValue(): Local {
        return this.currentLocal.value;
    }

    private cleanLocal(response: any): void {
        this.updateLocalValue(new Local());
        localStorage.removeItem('currentLocal');
    }

    public saveLocal(local: any): void {
        this.updateLocalValue(local);
        localStorage.setItem('currentLocal', JSON.stringify(local));
    }

    public updateLocalValue(local: any): void {
        this.currentLocal.next(local);
    }

    public editLocal(local: any, success: any, error: any): void {
        this.localResource.EditLocal(local, success, error, Local.getIncludes());
    }
}
