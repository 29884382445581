/**
 * Method to convert a object to FormData to send it for a request with files
 *
 * @param {Object} object
 * @returns {FormData} formData
 */
export function convertToFormData(data){
    return setObjectInFormData(data, new FormData(), '');
};

/**
 * Method to convert a object to FormData to send it for a request with files
 *
 * @param {Object} object
 * @param {FormData} formData
 * @param {string} prefix
 * @returns {FormData} formData
 */
function setObjectInFormData(object, formData, prefix){
    console.log("setObjectInFormData", object, prefix);
    for(var property in object){
        var propertyName = (prefix) ? prefix.replace('PROPERTY', property) : property;

        if(object[property]){
            switch ( checkTypeOfValue(object[property]) ) {
                case 'object':
                    setObjectInFormData(object[property], formData, propertyName + '[PROPERTY]' );
                break;
                case 'array':
                    setArrayInFormData(object[property], formData, property, prefix);
                break;
                default:
                    formData.append(propertyName, object[property]);
            }
        }else if(object[property] === null){
            formData.append(propertyName, '');
        }
    }
    return formData;
}

function setArrayInFormData(array, formData, arrayName, prefix){
    for(var i=0; i<array.length; i++){
        var arrayPrefix = prefix +  arrayName + '['+ i + ']';
        var type = checkTypeOfValue(array[i]);

        switch (type) {
            case 'object':
                arrayPrefix =  arrayPrefix  + '[PROPERTY]' ;
                setObjectInFormData(array[i], formData, arrayPrefix);
            case 'file':
                formData.append(arrayPrefix, array[i]);
            case 'string':
                formData.append(arrayPrefix, array[i]);
            default:
                //formData.append(arrayPrefix, array[i]);
        }
    }
}

function checkTypeOfValue(field){
    switch (typeof (field)) {
        case 'object':
            if( Array.isArray(field) || field instanceof(FileList) )
               return 'array';
            else if( field instanceof(File) ){
                return 'file';
            }else{
                return 'object';
            }
        case 'function':
            return 'function';
        default:
            return  typeof (field);
    }
}

