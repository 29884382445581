import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';
import { convertToFormData } from 'common/helpers/resources/convertToFormData';
import { convertToFormDataM } from 'common/helpers/resources/convertToFormDataM';

@Injectable({ providedIn: 'root' })
export class ProductResource extends Resource {
    protected availableParams: Array<string> = [
        'include',
        'label',
        'search',
        'limit',
        '_method',
    ];
    protected availableUrlParams: Array<string> = [
        'id'
    ];

    /**
     * Constructor
     *
     * @param {HttpClient} http
     */
    public constructor(protected http: HttpClient) {
        super("/products");
    }

    /**
     * Get publications
     *
     * @return any
     */
    public GetProduct(id: any, _success?: any, _error?: any, include?: any): any {
        return this.Get(this.url('/:id'), {id: id, include: include}, _success, _error);
    }

    /**
     * Get category
     *
     * @return any
     */
    public GetProducts(_success?: any, _error?: any, include?: any): any {
        return this.Get(this.url(), {include: include}, _success, _error);
    }

    /**
     * Get category
     *
     * @return any
     */
    public GetArchivedProducts(_success?: any, _error?: any, include?: any): any {
        return this.Get(this.url('archived'), {include: include}, _success, _error);
    }

    /**
     *  create
     *
     * @returns
     */
    public Create(data: any, success?: any, error?: any){
        return this.Post(this.url(), convertToFormData(data), null, success, error);
    }


    /**
     *  create
     *
     * @returns
     */
    public CreateMultiple(data: any, success?: any, error?: any){
        return this.Post(this.url("/create-multiple"), convertToFormDataM(data), null, success, error);
    }


    /**
     *  edit
     *
     * @returns
     */
    public Edit(data: any, success?: any, error?: any){
        return this.Post(this.url("/:id"), convertToFormData(data), {id: data.id, _method: 'PATCH'}, success, error);
    }

    /**
     *  delete
     *
     * @returns
    */
    public Delete(id: any, success?: any, error?: any){
        return super.Delete(this.url("/:id"), {id: id}, success, error);
    }


    /**
     *  Restore
     *
     * @returns
     */
    public Restore(id: any, success?: any, error?: any){
        return this.Post(this.url("/restore/:id"), {}, {id: id, _method: 'PATCH'}, success, error);
    }
}
