import { Model } from 'common/helpers/models/model.abstract';


export class ShippingPrice extends Model {

    // id: number;
    local_id: number;
    limit_low: number;
    price:number;

    protected static includes: any;

       /**
     * Constructor
     *
     * @param publication
     */
    public constructor(shippingPrice?: any) {
        super();
        shippingPrice = shippingPrice || {};
        // this.id = shippingPrice.id || null;
        this.local_id = shippingPrice.local_id || null;
        this.limit_low = shippingPrice.limit_low || null;
        this.price = shippingPrice.price || null;
     
    }


    public static getIncludes(...args: any): string {
        return ShippingPrice.transformIncludes(ShippingPrice.includes, ...args);
    }

    public static classIncludes(): any {
        return ShippingPrice.includes;
    }
}
