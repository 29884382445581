import { Model } from 'common/helpers/models/model.abstract';


export class Discount extends Model {

    // id: number;
    local_id: number;
    limit_low: number;
    discount:number;

    protected static includes: any;

       /**
     * Constructor
     *
     * @param publication
     */
    public constructor(discount?: any) {
        super();
        discount = discount || {};
        // this.id = shippingPrice.id || null;
        this.local_id = discount.local_id || null;
        this.limit_low = discount.limit_low || null;
        this.discount = discount.discount || null;
     
    }


    public static getIncludes(...args: any): string {
        return Discount.transformIncludes(Discount.includes, ...args);
    }

    public static classIncludes(): any {
        return Discount.includes;
    }
}
