import { NgModule } from '@angular/core';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalEditLevel } from 'common/ui/modals/EditLevel/edit-level.component';
import { ModalProductDetail } from 'common/ui/modals/ProductDetails/product-details.component';

@NgModule({
    imports: [
    	ReactiveFormsModule,
    	CommonModule,
        NgbModule,
        NgxSpinnerModule,
    ],
    declarations: [
        ModalEditLevel,
        ModalProductDetail,
    ],
    exports: [
        ModalEditLevel,
        ModalProductDetail,
    ],
	entryComponents: [
        ModalEditLevel,
        ModalProductDetail,
	],
})
export class ModalsModule { }