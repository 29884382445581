<div class="modal-header">
  <h4 class="modal-title">Detalles de publicación</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
    <div class="container blockProductDetail">
        <div class="row">
            <div class="col-12 col-sm-6 m-0 p-0 d-inline-block" style="min-height: 300px;">
                <ngb-carousel *ngIf="product.gallery.length">
                  <ng-template *ngFor="let photo of product.gallery" ngbSlide>
                    <img  src="{{photo ? photo : ''}}" default="assets/images/nophoto.jpg" class="d-block w-100">
                  </ng-template>
                  <ng-template *ngIf="!product.gallery?.length" ngbSlide>
                    <img src="assets/images/nophoto.jpg" class="d-block w-100" alt="Second slide">
                  </ng-template>
                </ngb-carousel>

                <div *ngIf="!product.gallery.length">
                    <img src="assets/images/nophoto.jpg" default="assets/images/nophoto.jpg" class="d-block w-100" >
                </div>
            </div>
            <div class="col-12 col-sm-6 d-inline-block blockInfo">
              <a class="blockUser" (click)="goToUser(product.user)">
                  <div class="media chat-list-text py-1">
                    <span class="avatar avatar-md mr-2">
                      <img [src]="product.user?.photo" default="assets/images/nophoto.jpg" alt="Avatar">
                      <!-- <img src="assets/img/portrait/small/avatar-s-9.png" default="assets/images/nophoto.jpg" alt="Avatar"> -->
                      <i></i>
                    </span>
                    <div class="media-body" style="align-self: center;">
                      <h6 class="list-group-item-heading mb-1 link">
                        {{product.user?.name || 'Username for test'}}
                      </h6>
                      <span class="">{{product.created_at | date: "dd-MMM-yyyy hh:mm"}}</span>
                    </div>
                  </div>
              </a>
              <p class="card-text">{{product.description}}</p>
              <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-left">
                <li [ngbNavItem]="1">
                  <a ngbNavLink class="nav-link d-flex align-items-center">
                    <i class="ft-info mr-1"></i>
                    <span class="d-none d-sm-block f-12">General</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div *ngIf="product.category_id" class="product-info">
                      <div><i class="fa fa-tag"></i><b>Categoría</b></div>
                      <span>{{product.category.name}}</span>
                    </div>
                    <div *ngIf="product.campus" class="product-info">
                      <div><i class="fa fa-university"></i><b> Campus</b></div>
                      <span>{{product.campus?.name}}</span>
                    </div>

                    <div *ngIf="product.division" class="product-info">
                      <div><i class="fa fa-university"></i><b> División</b></div>
                      <span>{{product.division?.name}}</span>
                    </div>
                    <div *ngIf="product.price" class="product-info">
                      <div><i class="fa fa-dollar"></i><b> Precio</b></div>
                      <span>{{product.price}}</span>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink class="nav-link d-flex align-items-center">
                    <i class="ft-list mr-1"></i>
                    <span class="d-none d-sm-block f-12">Detalles</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div *ngFor="let field of product.fields" class="product-info">
                      <div><i class="fa fa-tag"></i><b> {{formatLabel(field.label, product.category_id)}}</b></div>
                      <span [innerHTML]="field.value"></span>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div class="tab-content">
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
        </div><!-- End .row -->
    </div><!-- End .container -->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger btn-sm" (click)="activeModal.close('Close click')">Cerrar</button>
</div>
<ngx-spinner></ngx-spinner>