import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the GroupByPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({name: 'groupBy'})
export class GroupByPipe implements PipeTransform {
    transform(collection: any, property: string): any {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if(!collection) {
        	console.log('%c NOT COLLECTION' , 'background-color:black;color:white;');
            return null;
        }

        const groupedCollection = collection.reduce((previous, current)=> {
            if(!previous[current[property].name]) {
                previous[current[property].name] = [current];
            } else {
                previous[current[property].name].push(current);
            }

            return previous;
        }, {});


        // this will return an array of objects, each object containing a group of objects    
        return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
    }
}
