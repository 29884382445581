import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';
import { convertToFormData } from 'common/helpers/resources/convertToFormData';

@Injectable({ providedIn: 'root' })
export class DiscountResource extends Resource {
    protected availableParams: Array<string> = [
        'include',
        'label',
        'search',
        '_method',
    ];
    protected availableUrlParams: Array<string> = [
        'id'
    ];
    // protected include = 'photo,subcategories'; //TEMP: QUITAR INCLUDES DE RESOURCES, buscar en el modelo

    /**
     * Constructor
     *
     * @param {HttpClient} http
     */
    public constructor(protected http: HttpClient) {
        super("/localdiscounts");
    }

     /**
     *  create
     *
     * @returns
     */
    public Create(data: any, success?: any, error?: any){
        return this.Post(this.url(), {"discounts":data}, null, success, error);
    }

}
