import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Auth, User } from 'common/models';
import { UserResource } from 'common/resources';


@Injectable({ providedIn: 'root' })
export class UserService {

    public user: User;
    public currentUser: Observable<User>;
    public currentUserSubject: BehaviorSubject<User>;

    public constructor(private userResource : UserResource){
        this.user = new User();
        this.currentUserSubject = new BehaviorSubject(this.user);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    init(user?: any){
        if(!user){
            user = new User(JSON.parse(localStorage.getItem('currentUser')) );
            this.currentUserSubject = new BehaviorSubject(user);
            this.currentUser = this.currentUserSubject.asObservable();
        }

        this.updateUserValue( (user) ? user : new User());
    }

    public get currentUserValue() {
        return this.currentUser;
    }

    private cleanUser(response: any): void {
        this.updateUserValue(new User());
        localStorage.removeItem('currentUser');
    }

    public saveUser(user: any): void {
        this.updateUserValue(user);
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    public updateUserValue(user: any): void {
        this.currentUserSubject.next(user);
    }

    public editUser(user: any, success: any, error: any): void {
        this.userResource.EditUser(user, success, error, User.getIncludes());
    }
}
