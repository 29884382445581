import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Resource } from 'common/helpers/resources/resource.abstract';

@Injectable({ providedIn: 'root' })
export class UserResource extends Resource {
    protected ENDPOINT_CONEKTA_CARD: string = "/card";
    protected availableParams: Array<string> = ["include"];
    protected availableUrlParams: Array<string> = [
        'id'
    ];
	protected user: any;
    protected onUserChanged: BehaviorSubject<any>;

    public constructor(protected http: HttpClient) {
        super("/users");
    	this.onUserChanged = new BehaviorSubject({});
    }

	public Update(id: any, data: any, _success?: any, _error?: any): any {
        return this.Post(this.url("/:id"), data, {id: id}, _success, _error);
	}

	public UpdatePassword(data: any){
        return this.Post(this.url(), data);
		// return this.http.patch(`${environment.apiUrl}/users` , data );
	}

    public GetConektaCard(include?: any, success?: any, error?: any){
        return this.Get(this.url(this.ENDPOINT_CONEKTA_CARD), {include: include}, success, error);
    }

	/**
     * Edit user
     *
     * @param user
     * @returns {any}
    */
    public EditUser(data: any, _success?: any, _error?: any, include?: any): any {
         return this.Post(this.url("/:id"), data, {id: data.id, _method: 'PUT'}, _success, _error);
    }

    /**
     * Get users
     *
     * @return any
     */
    public GetUser(id: any, _success?: any, _error?: any, include?: any): any {
        return this.Get(this.url('/:id'), {id: id, include: include}, _success, _error);
    }

    /**
     * Get category
     *
     * @return any
     */
    public GetUsers(_success?: any, _error?: any, include?: any): any {
        return this.Get(this.url(), {include: include}, _success, _error);
    }


     /**
     * Edit user
     *
     * @param user
     * @returns {any}
    */
    public ResetPassword(data: any, _success?: any, _error?: any): any {
        return this.Post(this.url("/password/reset"), data, _success, _error);
    }
}
