import { Model } from 'common/helpers/models/model.abstract';
import { Category} from 'common/models/category.model';

export class Product extends Model {

    id: number;
    photo: string = 'assets/images/defaultproduct.png';
    name: string;
    category_id: any;
    description:string;
    price:number;
    wholesales:any;
    weight:number =1;
    category:any ={
        name: null
    };
    discount: number;
    local:any = {
        id:null,
        name:null
    };
    deleted_at:any = null;
    reference:string;


    protected static includes: any = {
        'category': Category
    }

       /**
     * Constructor
     *
     * @param publication
     */
    public constructor(product?: any) {
        super();
        product = product || {};
        this.id = product.id || null;
        this.photo = product.photo || null;
        this.name = product.name || null;
        this.description = product.description || null;
        this.reference = product.reference || null;
        this.price = product.price || null;
        this.category_id = product.category_id || null;
        this.wholesales  = product.wholesales || [{price: 0, limit_low:0}];
        this.discount  = product.discount || null;
        this.weight  = product.weight || 1;
        this.category  = product.category || {
            name: null
        };
        this.local  = product.local || {
            id:null,
            name: null
        };
        this.deleted_at  = product.deleted_at || null;
    }


    public static getIncludes(...args: any): string {
        return Product.transformIncludes(Product.includes, ...args);
    }

    public static classIncludes(): any {
        return Product.includes;
    }
}
