import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { environment } from 'environments/environment';
import { Resource } from 'common/helpers/resources/resource.abstract';

@Injectable({ providedIn: 'root' })
export class AuthenticationResource extends Resource {
    protected URL_SIGNING_UP: string = "/signing-up";
    protected URL_FORGOT_PASS: string = "/forgot-password";
    protected AUTH_CHECK: string = "/user/profile";
    protected URL_LOGIN: string = "/clients/web/admin/login";
    protected URL_LOGOUT: string = "/logout";
    protected URL_CONFIRM_MAIL: string = "/verify/:id";
    protected availableParams: Array<string> = ["include", "expires", "signature"];
    protected availableUrlParams: Array<string> = ["id"];

    /**
     * Constructor
     *
     * @param {HttpClient} http
     */
    public constructor(protected http: HttpClient) {
        super('');
    }

    public setDataAuth(user: any): any{
        return {
            id: (user.id) ? user.id: undefined,
            name: user.name,
            phone: user.phone,
            email: user.email,
            password: (user.password) ? user.password : undefined
        }
    }

    /**
     *  Signin
     *
     * @returns
     */
    public Signin(data: any, success?: any, error?: any){
        return this.Post(this.url(this.URL_SIGNING_UP), this.setDataAuth(data), null, success, error);
    }

    /**
     *  auth
     *
     * @return
     */
    public Auth(include?: any, success?: any, error?: any){
        return this.Get(this.url(this.AUTH_CHECK), {include: include}, success, error);
    }

    /**
     *  confirm Email
     *
     * @returns
     */
    public ConfirmEmail(data: any, success?: any, error?: any){
        return this.Get(this.url(this.URL_CONFIRM_MAIL), {id: data.user_id, expires: data.expires, signature: data.signature}, success, error);
    }

    /**
     *  forgot Password
     *
     * @returns
     */
    public ForgotPassword(data: any, success?: any, error?: any){
        return this.Post(this.url(this.URL_FORGOT_PASS), data, null, success, error);
    }

    /**
     *  login
     *
     * @returns
     */
    public Login(data: any, include?: any, success?: any, error?: any){
        return this.Post(this.url(this.URL_LOGIN), data, {include: include}, success, error);
    }

    /**
     *  login
     *
     * @returns
     */
    public Logout(success?: any, error?: any){
        return this.Delete(this.url(this.URL_LOGOUT), {id: null}, success, error);
    }
}
