// import { FuseConfig } from '@fuse/types';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const appConfig: any = {
    // Color themes can be defined in src/app/app.theme.scss
    passwordMinLength:6,
	RFCPattern : /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
	zipPattern : /^\d{5}$/,
	countryCodePattern : /^[+]\d{2,4}$/,
	completeName : /^([A-zÀ-ÿ',\-]+\s[A-zÀ-ÿ',\-]+)+$/,
	phonePattern : /^\d{10}$/,

};
