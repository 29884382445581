import { Component, Inject, Input } from '@angular/core';
import { appConfig } from 'common/app-config';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

// import { LevelResource } from 'common/resources';

@Component({
    selector: 'modal-productDetail',
    templateUrl: 'product-details.component.html',
    styleUrls: ['product-details.component.scss'],
})

export class ModalProductDetail {
    appConfig: any;
    loading: boolean;
    server_error: string = '';
    public constructor(
        public activeModal: NgbActiveModal,
        private spinner: NgxSpinnerService,
        private router: Router,
        public toastr: ToastrService,
    ) {
        this.appConfig = appConfig;
    }

    @Input() product;

    /**
 * On init
 */
    ngOnInit(): void {
        console.log("product", this.product);
    }

    public closeModal(data?: any){
        //this._dialogRef.close(data);
    }

    formatLabel(label: string, product_category_id): string {
        switch (label) {
            case "who":
                return  product_category_id == 6 ?  "¿A quién?" : "¿Por quién?"
            break;
            case "language": return "Idioma"
            break;
            case "time":  return "Duración"
            break;
            case "schedule": return "Horarios"
            break;
            case "level":  return "Nivel"
            break;
            case "buziness_type": return "Tipo negocio"
            break;
            case "address": return "Dirección"
            break;
            case "poste": return "Puesto"
            break;
            case "city": return "Ciudad"
            break;
            case "start": return "Inicio"
            break;
            case "end": return "Destino"
            break;
            case "date": return "Fecha"
            break;
            case "place": return "Lugar"
            break;
            case "tel":case "phone":
                return "Teléfono"
            break;
            case "email": case "url": default:
                return label;
            break;
        }
    }

    goToUser(user){
        user = user || {id: 1};
        this.activeModal.dismiss('Cross click')
        this.router.navigate(['/pages/users', {id: user.id}]);
    }

    showSpinner(type?: string, bgColor?: string, color?: string){
      this.spinner.show(undefined, {
        type: type || 'ball-triangle-path',
        size: 'medium',
        bdColor: bgColor || 'rgba(0, 0, 0, 0.8)',
        color: color || '#fff',
        fullScreen: true
     });
    }
}
