import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';
import { convertToFormData } from 'common/helpers/resources/convertToFormData';

@Injectable({ providedIn: 'root' })
export class OrderResource extends Resource {
    protected availableParams: Array<string> = [
        'include',
        'label',
        'search',
        '_method',
    ];
    protected availableUrlParams: Array<string> = [
        'id'
    ];
    // protected include = 'photo,subcategories'; //TEMP: QUITAR INCLUDES DE RESOURCES, buscar en el modelo

    /**
     * Constructor
     *
     * @param {HttpClient} http
     */
    public constructor(protected http: HttpClient) {
        super("/suborders");
    }

    /**
     * Get categories
     *
     * @return any
     */
    public GetOrder(id: any, _success?: any, _error?: any, include?: any): any {
        return this.Get(this.url('/:id'), {id: id, include: 'order'}, _success, _error);
    }

    /**
     * Get categories
     *
     * @return any
     */
    public GetOrders(_success?: any, _error?: any, include?: any): any {
        return this.Get(this.url(), {include: include}, _success, _error);
    }

    // /**
    //  * Get subcategories
    //  *
    //  * @return any
    //  */
    // public GetSubcategories(label: string, _success?: any, _error?: any, include?: any): any {
    //     include = (include) ? include : this.include;
    //     return this.Get(this.url(), {label: label, include: include}, _success, _error);
    // }

     /**
     *  create
     *
     * @returns
     */
    public Create(data: any, success?: any, error?: any){
        return this.Post(this.url(), convertToFormData(data), null, success, error);
    }

    /**
     *  edit
     *
     * @returns
     */
    public Edit(data: any, success?: any, error?: any){
        return this.Post(this.url("/:id?_method=PATCH"), convertToFormData(data), {id: data.id}, success, error);
    }

    /**
     *  delete
     *
     * @returns
    */
    public Delete(id: any, success?: any, error?: any){
        return super.Delete(this.url("/:id"), {id: id}, success, error);
    }

    /**
     *  edit
     *
     * @returns
     */
    public ChangeStatus(data: any, success?: any, error?: any){
        return this.Post(this.url("/change-status?inlcude=status"), data,null, success, error);
    }


    /**
     *  edit
     *
     * @returns
     */
    public ValidateOrder(data: any, success?: any, error?: any){
        return this.Post(this.url("/validate-order?inlcude=status"), data,null, success, error);
    }
}
