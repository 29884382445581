import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Resource } from 'common/helpers/resources/resource.abstract';
import { convertToFormData } from 'common/helpers/resources/convertToFormData';
import { convertToFormDataM } from 'common/helpers/resources/convertToFormDataM';

@Injectable({ providedIn: 'root' })
export class CategoryResource extends Resource {
    protected availableParams: Array<string> = [
        'include',
        'label',
        'search',
        '_method',
    ];
    protected availableUrlParams: Array<string> = [
        'id'
    ];
    // protected include = 'photo,subcategories'; //TEMP: QUITAR INCLUDES DE RESOURCES, buscar en el modelo

    /**
     * Constructor
     *
     * @param {HttpClient} http
     */
    public constructor(protected http: HttpClient) {
        super("/categories");
    }

    /**
     * Get categories
     *
     * @return any
     */
    public GetCategory(id: any, _success?: any, _error?: any, include?: any): any {
        return this.Get(this.url('/:id'), {id: id, include: 'categories'}, _success, _error);
    }

    /**
     * Get categories
     *
     * @return any
     */
    public GetCategories(_success?: any, _error?: any, include?: any): any {
        return this.Get(this.url(), {include: include}, _success, _error);
    }

    // /**
    //  * Get subcategories
    //  *
    //  * @return any
    //  */
    // public GetSubcategories(label: string, _success?: any, _error?: any, include?: any): any {
    //     include = (include) ? include : this.include;
    //     return this.Get(this.url(), {label: label, include: include}, _success, _error);
    // }

     /**
     *  create
     *
     * @returns
     */
    public Create(data: any, success?: any, error?: any){
        return this.Post(this.url(), convertToFormDataM(data), null, success, error);
    }

    /**
     *  edit
     *
     * @returns
     */
    public Edit(data: any, success?: any, error?: any){
        return this.Post(this.url("/:id?_method=PATCH"), convertToFormDataM(data), {id: data.id}, success, error);
    }

    /**
     *  delete
     *
     * @returns
    */
    public Delete(id: any, success?: any, error?: any){
        return super.Delete(this.url("/:id"), {id: id}, success, error);
    }
}
