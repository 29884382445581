import { Model } from 'common/helpers/models/model.abstract';
import { Local } from 'common/models/local.model';

export class Auth extends Model {
    access_token?: string;  //---> access_token
    expires_in?: string;
    refresh_token?: string;
    token_type?: string;

    // protected static includes: any = {
    //     local:null
    // };

    public constructor(auth?: any) {
        super();
        auth = auth || {};
        this.access_token = auth.access_token || null;
        this.expires_in = auth.expires_in || null;
        this.refresh_token = auth.refresh_token || null;
        this.token_type = auth.token_type || null;
    }

    public static getIncludes(...args: any): string {
        return Auth.transformIncludes(Auth.includes, ...args);
    }

    public static classIncludes(): any {
        return Auth.includes;
    }
}
