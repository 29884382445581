import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'common/services/';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available

            request = request.clone({
                setHeaders: {
					'Accept-Language': 'es,es-MX,en-US,en',
                    'Access-Control-Allow-Origin': '*'
				}
            });

        return next.handle(request);
    }
}
