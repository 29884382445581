import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from 'common/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService,
        private router: Router,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            switch (err.status) {
                case 401:
                    // auto logout if 401 response returned from api
                    console.log('401 catched - for now dont do noting but this is for Unauthorized = trying to access a ressource without permission. Ususally logout and back to login ...');
                    //this.authService.logout();
                    this.authService.cleanSession();
                    localStorage.removeItem('currentUser');
                    this.router.navigate(['/pages/login']);
                    // location.reload(true)
                break;
                case 404:
                    return throwError('Ruta no disponible o no existe por el momento, intentelo de nuevo más tarde.');
                    console.log('401 catched - for now dont do noting but this is for Unauthorized = trying to access a ressource without permission. Ususally logout and back to login ...');
                break;
                default:
                    // code...
                break;
            }
            
            let error = err.error.message || err.message;

            if (err.error.errors){
                error = "";
                for (var propName in err.error.errors) {
                    error +=  propName + " : " + err.error.errors[propName] + '<br>' ;
                }
            }

            return throwError(error);
        }))
    }
}
